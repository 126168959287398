@import '@fontsource/open-sans/300.css';
@import '@fontsource/open-sans/400.css';
@import '@fontsource/open-sans/500.css';
@import '@fontsource/open-sans/600.css';
@import '@fontsource/open-sans/700.css';
@import '@fontsource/open-sans/800.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Open Sans';
  font-weight: 400;
  color: #383231 !important;
}

.cap_symbol {
  font-family: ABeeZee;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: var(--LIN-OG, linear-gradient(93deg, #2D8DED 0%, #1F63A7 110.62%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@layer components {
  .bg_workforce {
    /* background: linear-gradient(190deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.464) 100%); */
    background: linear-gradient(180deg, #2D8DED 32.42%, rgba(31, 99, 167, 0.1) 111.49%);
  }

  .blue_background {
    background: var(--linog, linear-gradient(90deg, #2d8ded 0%, #1f63a7 100%));
  }

  .center_desc_wrap {
    background: var(--linog, linear-gradient(132deg, #2d8ded 0%, #1f63a7 100%));
    box-shadow: 0px -6px 18px 0px rgba(0, 0, 0, 0.25) inset;
    color: white;
  }

  .blue_grad_btn {
    border-radius: 8px;
    background: var(--linog, linear-gradient(132deg, #2d8ded 0%, #1f63a7 100%));
  }

  .center_desc_nobg {
    background: transparent !important;
    box-shadow: none;
  }

  .footer_wrap {
    min-height: 283px;
    background: linear-gradient(90deg, #FFF 6.71%, #CBCBCB 104.23%);
    background-size: contain;
  }

  .footer_wrap_mobile {
    background: linear-gradient(90deg, #FFF 6.71%, #CBCBCB 104.23%);
    background-size: contain;
  }

  .home_mb_bg {
    background: url(./assets/images/home_mb_bg.png) no-repeat;
    background-size: cover !important;
  }

  .faq_expanded {
    background: var(--LIN-OG, linear-gradient(93deg, #2D8DED 0%, #1F63A7 110.62%));
    color: white !important;
  }

  .faq_expanded .chapter_bold {
    color: white !important;
    -webkit-text-fill-color: white;
  }

  .faq_collapsed {
    background-color: transparent;
    border: 1px solid #2573C1;
  }

  .workforce_mb_bg {
    background: url(./assets/images/workforce_mb_bg.png) no-repeat;
    background-size: cover !important;
  }

  .stem_outreach_mb_bg {
    background: url(./assets/images/stem_outreach_mb_bg.png) no-repeat;
    background-size: cover !important;
  }

  .cs_outreach_mb_bg {
    background: url(./assets/images/cs_coding_mb_bg.png) no-repeat;
    background-size: cover !important;
  }

  .vrdriver_mb_bg {
    background: url(./assets/images/vr_driver_mb_bg.png) no-repeat;
    background-size: cover !important;
  }

  .venture_mb_bg {
    background: url(./assets/images/venture_mb_bg.png) no-repeat;
    background-size: cover !important;
  }

  .process_mb_bg {
    background: url(./assets/images/process_mb_bg.png) no-repeat;
    background-size: cover !important;
  }

  .team_mb_bg {
    background: url(./assets/images/meet_team_md_bg.png) no-repeat;
    background-size: cover !important;
  }

  .main_home_bg {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/home_main_log.webp) no-repeat !important;
    /* background: url(./assets/images/home_main_log.png) no-repeat !important; */
    background-size: cover !important;
  }

  .stem_cover_bg {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/company_log.png) no-repeat !important;
    background-size: cover !important;
  }

  .career_cover_bg {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/readline_programs.webp) no-repeat !important;
    background-size: cover !important;
  }

  .wind_turbine_hero_bg {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/wind_turbine_hero.png) no-repeat !important;
    background-size: cover !important;
  }

  .bridge_bg {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/bridge_bg.png) no-repeat !important;
    background-size: cover !important;
  }

  .solar_energy_bg {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/solar_enegry_bg.png) center bottom no-repeat !important;
    background-size: cover !important;
  }

  .racecar_bg {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/racecar_bg.png) center bottom no-repeat !important;
    background-size: cover !important;
  }

  .tower_bg {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/tower_bg.png) no-repeat !important;
    background-size: cover !important;
  }

  .glider_plane_bg {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/glider_plane_bg.png) no-repeat !important;
    background-size: cover !important;
  }

  .prosthetics_bg {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/prosthetics_bg.png) no-repeat !important;
    background-size: cover !important;
  }

  .hydraulics_bg {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/hydraulics_bg.png) no-repeat !important;
    background-size: cover !important;
  }

  .work_force_bg {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/work_force_bg.png) no-repeat !important;
    background-size: cover !important;
  }

  .vr_driver_bg {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/vr_driver_bg.png) no-repeat !important;
    background-size: cover !important;
  }

  .bsd_hero_bg {
    background: url(./assets/images/bsd_hero_bg.png) no-repeat !important;
    background-size: cover !important;
  }

  .ventures_bg {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/ventures_bg.png) top center no-repeat !important;
    background-size: cover !important;
  }

  .process_bg {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/process_bg.png) center top no-repeat !important;
    background-size: cover !important;
  }

  .meet_team_bg {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/meet_team_bg.png) center top no-repeat !important;
    background-size: cover !important;
  }

  .progress_bk {
    background: url(./assets/images/progress_bg.svg) no-repeat;
    background-size: contain;
    filter: drop-shadow(0px 8px 18px rgba(41, 56, 165, 0.35));
  }

  .progress_bar_bg {
    border-radius: 20px;
    background: var(--linog, linear-gradient(222deg, #2d8ded 0%, #1f63a7 100%));
  }

  .fit_wrap {
    background: var(--linog, linear-gradient(132deg, #2d8ded 0%, #1f63a7 100%));
    color: white;
  }

  .fit_wrap_bg {
    background: url(./assets/images/bg_logo_mark.png) center center no-repeat, var(--linog, linear-gradient(132deg, #2d8ded 0%, #1f63a7 100%));
    background-size: contain;
    color: white;
  }

  .ar_vr_sec_bg {
    background: url(./assets/images/ar_vr_section_bg.webp) center center no-repeat;
    background-size: contain;
  }

  .home_ar_vr_sec_bg {
    background: url(./assets/images/home_ar_vr_section_bg.webp) center center no-repeat;
    background-size: cover;
    border-radius: 25px;
  }

  .shadow_small {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: #FFF;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.08);
  }

  .request_xl_form {
    border-radius: 20px;
    border: 1px solid #2E90F2;
    background: #FFF;
  }

  .request_ml_form {
    border: 1px solid #2E90F2;
    background: #FFF;
  }

  .sm_request_form_title {
    color: #4387EB;
    leading-trim: both;
    text-edge: cap;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .player-wrapper {
    position: relative;
    /* border-radius: 5px; */
    height: 100%;
    min-height: 200px;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 12px;
    height: 100%;
    overflow: hidden;
  }

  .personalCards .react-player {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .personalCards_1 .react-player {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .circle-background,
  .circle-progress {
    fill: none;
  }

  .circle-background {
    fill: #FFF;
    /* fill: var(--LIN-OG, linear-gradient(93deg, #2D8DED 0%, #1F63A7 110.62%)); */
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    stroke: rgba(0, 0, 0, 0.10);
  }

  .circle-progress {
    /* stroke: rgba(255, 255, 255, 0.8); */
    stroke: #1f63a7;
    fill: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.8) 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .circle-text {
    font-size: 2.2em;
    font-weight: 800;
    fill: #1F63A7;
  }

  .left_round_bg {
    border-radius: 0px 20px 20px 0px;
    background: var(--linog, linear-gradient(132deg, #2d8ded 0%, #1f63a7 100%));
  }

  .md_menu_bg {
    background: var(--linog, linear-gradient(132deg, #2d8ded 0%, #1f63a7 100%));
  }

  .header_btn {
    background: var(--linog, linear-gradient(132deg, #2d8ded 0%, #1f63a7 100%));
  }

  .blog_tab_selected {
    background: var(--linog,
        linear-gradient(93deg, #2d8ded 0%, #1f63a7 110.62%));
  }

  .community_c_bg {
    position: relative;
  }

  .view_btn {
    border-radius: 8px;
    background: var(--LIN-OG, linear-gradient(93deg, #2D8DED 0%, #1F63A7 110.62%));
    color: white;
  }

  .community_c_bg:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background: url(./assets/larges/community_sub.png) no-repeat;
    background-position: 50% 0;
    background-size: cover;
    z-index: 0;
  }

  .community-bullets .bullet.active span {
    display: block;
  }


  .text_gradient_color {
    background: var(--LIN-OG, linear-gradient(93deg, #2D8DED 0%, #1F63A7 110.62%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .chapter_bold {
    background: var(--LIN-OG, linear-gradient(93deg, #2D8DED 0%, #1F63A7 110.62%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .bg_curve_border {
    background: url(./assets/images/video_curve_bg.svg) 0px 0px;
    background-size: cover;
  }

  .blue_list {
    background: url('./assets/images/circle_check.svg') no-repeat left 7px;
    padding-left: 25px;
    list-style-type: none;
  }

  .blue_md_list {
    background: url('./assets/images/circle_check.svg') no-repeat left 7px;
    padding-left: 25px;
    list-style-type: none;
  }

  .white_list {
    background: url('./assets/images/white_checked.svg') no-repeat left 8px;
    padding-left: 18px;
    list-style-type: none;
  }

  .we_fit_bullet {
    background: var(--LIN-OG, linear-gradient(93deg, #2D8DED 0%, #1F63A7 110.62%));
  }

  .sm-max-wd {
    width: calc(100vw - 66px);
  }

  .slider-wd {
    width: calc(90vw - 66px);
  }

  @screen md {
    .community-bullets {
      position: absolute;
      z-index: 30;
      left: calc(340px - 50vw);

    }

    .community-bullets .bullet:nth-child(1) {
      transform: rotate(0deg) translate(19rem);
    }

    .community-bullets .bullet:nth-child(2) {
      transform: rotate(36deg) translate(19rem) rotate(-36deg);
    }

    .community-bullets .bullet:nth-child(3) {
      transform: rotate(72deg) translate(18rem) rotate(-72deg);
    }

    .community-bullets .bullet:nth-child(4) {
      transform: rotate(108deg) translate(18rem) rotate(-108deg);
    }

    .community-bullets .bullet:nth-child(5) {
      transform: rotate(144deg) translate(18rem) rotate(-144deg);
    }

    .community-bullets .bullet:nth-child(6) {
      transform: rotate(180deg) translate(18rem) rotate(-180deg);
    }

    .community-bullets .bullet:nth-child(7) {
      transform: rotate(216deg) translate(18rem) rotate(-216deg);
    }

    .community-bullets .bullet:nth-child(8) {
      transform: rotate(252deg) translate(18rem) rotate(-252deg);
    }

    .community-bullets .bullet:nth-child(9) {
      transform: rotate(288deg) translate(18rem) rotate(-288deg);
    }

    .community-bullets .bullet:nth-child(10) {
      transform: rotate(324deg) translate(18rem) rotate(-324deg);
    }
  }

  @screen xl {
    .community-bullets {
      position: absolute;
      text-align: center;
      z-index: 30;
      width: 100%;
    }

    .community-bullets .bullet:nth-child(1) {
      transform: rotate(0deg) translate(21rem);
    }

    .community-bullets .bullet:nth-child(2) {
      transform: rotate(36deg) translate(21rem) rotate(-36deg);
    }

    .community-bullets .bullet:nth-child(3) {
      transform: rotate(72deg) translate(21rem) rotate(-72deg);
    }

    .community-bullets .bullet:nth-child(4) {
      transform: rotate(108deg) translate(21rem) rotate(-108deg);
    }

    .community-bullets .bullet:nth-child(5) {
      transform: rotate(144deg) translate(21rem) rotate(-144deg);
    }

    .community-bullets .bullet:nth-child(6) {
      transform: rotate(180deg) translate(21rem) rotate(-180deg);
    }

    .community-bullets .bullet:nth-child(7) {
      transform: rotate(216deg) translate(21rem) rotate(-216deg);
    }

    .community-bullets .bullet:nth-child(8) {
      transform: rotate(252deg) translate(21rem) rotate(-252deg);
    }

    .community-bullets .bullet:nth-child(9) {
      transform: rotate(288deg) translate(21rem) rotate(-288deg);
    }

    .community-bullets .bullet:nth-child(10) {
      transform: rotate(324deg) translate(21rem) rotate(-324deg);
    }

  }

  .community-bullets img {
    display: block;
    height: auto;
    border-radius: inherit;
  }

  .bullet-hover {
    @apply absolute bottom-0 -left-10 w-full h-full rounded-full bg-gradient-to-r from-[#2E90F2] to-[#1E39A3] opacity-80 translate-x-1/4 transition-all;
  }

  .community-bullets .bullet span {
    position: absolute;
    top: 35%;
    left: 22%;
    /* font-size: 32px; */
    font-weight: 600;
    color: white;
    display: none;
  }

  .community-bullets .bullet {
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 50%;
    -webkit-animation: roundItemFadeIn 0.8s 0.2s both;
    animation: roundItemFadeIn 0.8s 0.2s both;
  }

  .blue_gradient_color {
    font-weight: 700;
    background: -webkit-linear-gradient(0deg, #2e90f2, #1e39a3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }



  .bg_white_round {
    @apply bg-white rounded-lg;
  }

  .xl_grid_item {
    @apply grid-cols-3 grid-rows-4 px-0 ms-0;
  }

  .vr_xl_grid_center_img {
    @apply grid col-start-2 row-span-2 row-start-2 place-content-center;
  }

  .vr_grid_first_item {
    @apply grid col-span-1 col-start-2 row-span-1 place-items-end;
  }

  .vr_grid_second_item {
    @apply grid flex-col col-span-1 row-span-2 row-start-2 space-x-0 place-content-center gap-y-6;
  }

  .vr_gird_last_item {
    @apply grid col-start-2 row-span-1 row-start-4 place-content-center;
  }

  .sub_menu_item {
    @apply block px-4 py-2 text-sm text-white;
  }

  .menu_btn_item {
    @apply inline-flex w-full items-center justify-center h-[71px] gap-x-1 px-4 text-white text-sm;
  }

  .menu_items_wrap {
    @apply absolute left-0 z-10 !mt-0 w-max origin-top-left shadow-lg !rounded-md !bg-menu_blue border-0;
  }

  .menu_p_item_wrap {
    @apply flex items-center px-4;
  }

  .menu_sm_item {
    @apply py-1 text-base text-white;
  }

  .min_contact_wrap {
    min-height: calc(100vh - 95px - 435px);
  }

  .min_md_contact_wrap {
    min-height: calc(100vh - 95px - 490px);
  }

  .caption {
    position: relative;
    padding-bottom: 7px;
  }

  h4.caption::after {
    bottom: 0px;
    content: "";
    margin: 0 auto;
    position: absolute;
    width: 50px;
    height: 2px;
    left: 0px;
    background-color: #2f94f9;
  }

  .left_arrow {
    background: url(./assets/images/icon-left.png) no-repeat;
    height: 50px;
    width: 50px;
    opacity: .5;
  }

  .left_arrow:hover {
    opacity: 1;
  }


  .right_arrow {
    background: url(./assets/images/icons-right.png) no-repeat;
    height: 50px;
    width: 50px;
    opacity: .5;
  }

  .right_arrow:hover {
    opacity: 1;
  }

  .blog_wrap a {
    color: #337ab7;
  }

  .cursor-grab a {
    color: #444;
  }

  .learn_more_bg {
    background: var(--linog, linear-gradient(132deg, #2D8DED 0%, #1F63A7 100%));
    color: white;
  }

  .track_bar {
    opacity: 0.3;
    background: #281A17;
  }

  .tracker_select_bar {
    background: var(--LIN-OG, linear-gradient(93deg, #2D8DED 0%, #1F63A7 110.62%));
  }

  .blue_btn {
    background: var(--LIN-OG, linear-gradient(93deg, #2D8DED 0%, #1F63A7 110.62%));
  }

  .download_case_shadow {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.16)
  }

  .yellow_decoration {
    background: url(./assets/images/yellow_top_decoration.svg) left top no-repeat, url(./assets/images/yellow_bottom_decoration.svg) right bottom no-repeat;
  }

  .scrollbar_box {
    @apply flex w-1/4 bg-[#383231] rounded-full h-4 mx-auto relative max-md:hidden
  }

  .scrollbar_box .track {
    @apply cursor-auto h-full rounded-full absolute left-0 w-full
  }

  .scrollbar_box .thumb {
    @apply cursor-auto h-full rounded-full absolute bg-[#FCDC3E]
  }

  .scrollbar_box_blue {
    @apply flex w-1/3 md:w-1/4 bg-[#C9D6E2] rounded-full h-4 mx-auto relative
  }

  .scrollbar_box_blue .track {
    @apply cursor-auto h-full rounded-full absolute left-0 w-full
  }

  .scrollbar_box_blue .thumb {
    @apply cursor-auto h-full rounded-full absolute bg-[#2E90F2]
  }

  .sm-w {
    width: calc(100vw - 64px);
  }

  .meet_cover_bg_xl {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/meet_the_team.png) top center no-repeat;
    background-size: cover;
  }

  .meet_cover_bg_md {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/meet_the_team.png) center center no-repeat;
    background-size: cover;
  }

  .meet_cover_bg_landing_xl {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/meet_the_team_landing.png) top center no-repeat;
    background-size: cover;
  }

  .meet_cover_bg_landing_md {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/meet_the_team_landing.png) center center no-repeat;
    background-size: cover;
  }
}

@tailwind utilities;